<script>
import GlobalVue from '../helper/Global.vue'

export default {
  extends: GlobalVue,
  props:['name','label'],
  computed:{
    labelTxt(){
      return this.label || this.fields[this.name]
    }
  }
}
</script>

<template>
  <label :error="name" :label="labelTxt"></label>
</template>